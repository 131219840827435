@import "./theme";
//@import "../../node_modules/@nimbus/styles/default.scss";
@import "./custom";
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

.dialog-modal-box .mat-dialog-container {
  padding: 0;
}


.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  padding: 0px;
  line-height: 2rem;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  padding: 2px 4px;
  font-size: 12px;
}